import $ from "jquery";
import "slick-carousel";

function initSlider($slider) {
  /* let $slides = $slider.find(".js-slide");
  let $content = $slider.find(".js-slide__content");
  let currentSlide = 0;
  let isDragging = false;
  let startPos, currentPos;
 */
  if ($slider.length === 0 || $slider.hasClass("slider-initialized")) {
    return;
  }

  // Mark slider as initialized
  /* $slider.addClass("slider-initialized");

  $slides
    .eq(currentSlide)
    .css("display", "grid")
    .fadeIn(300)
    .addClass("slide-active");

  if ($content.find(".slider-nav").length === 0) {
    $content.append(
      '<div class="slider-nav"><span class="prev-slide"></span><span class="next-slide"></span></div>',
    );
  }

  function showSlide(index) {
    $slides
      .removeClass("slide-active")
      .fadeOut(100)
      .eq(index)
      .css("display", "grid")
      .fadeIn(100)
      .addClass("slide-active");
  }

  $slider.find(".next-slide").click(function () {
    currentSlide = (currentSlide + 1) % $slides.length;
    showSlide(currentSlide);
  });

  $slider.find(".prev-slide").click(function () {
    currentSlide = (currentSlide - 1 + $slides.length) % $slides.length;
    showSlide(currentSlide);
  });

  // Touch and Drag events
  $slider.on("mousedown touchstart", function (e) {
    isDragging = true;
    startPos = e.originalEvent.touches
      ? e.originalEvent.touches[0].clientX
      : e.clientX;
  });

  $slider.on("mousemove touchmove", function (e) {
    if (!isDragging) return;
    currentPos = e.originalEvent.touches
      ? e.originalEvent.touches[0].clientX
      : e.clientX;
  }); */

  /*   Touch handler, to review  
    $slider.on('mouseup touchend', function() {
        if (!isDragging) return;
        isDragging = false;
        let delta = startPos - currentPos;
        let threshold = 80; // Adjust threshold as needed
        if (Math.abs(delta) > threshold) { 
            if (delta > 0) {
                // Swipe left
                currentSlide = (currentSlide + 1) % $slides.length;
            } else {
                // Swipe right
                currentSlide = (currentSlide - 1 + $slides.length) % $slides.length;
            }
            showSlide(currentSlide);
        }
    }); */
}

function initSliderNav($slider) {
  let $content = $slider.find(".js-slide__content");
  const $prev = $slider.find(".js-prev-button");
  const $next = $slider.find(".js-next-button");
  
  
  if ($content.find(".slider-nav").length === 0) {
    $content.append('<div class="slider-nav"></span></div>');
  }
  
  const $sliderNav = $content.find(".slider-nav");
  $sliderNav.append($prev);
  $sliderNav.append($next);
}

function slickSlider($slider) {
  
  $slider.slick({
    arrows: true,
    dots: false,
    fade: true,
    cssEase: "linear",
    prevArrow:
      '<button type="button" class="slick-prev js-prev-button"></button>',
    nextArrow:
      '<button type="button" class="slick-next js-next-button"></button>',
  });

  initSliderNav($slider);
}

// Initializing each slider when the DOM is ready
$(document).ready(function ($) {
 /*  $(".js-new-slider").each(function () {
    slickSlider($(this));
  }); */
});
